import { getSalesViewStorageKey } from './getSalesViewStorageKey';
import { isSalesViewEnabled } from './isSalesViewEnabled';

export const toggleSalesView = (accountNumber: string) => {
    if (typeof window === 'undefined' || !accountNumber) {
        return;
    }

    const storageKey = getSalesViewStorageKey(accountNumber);
    const currentValue = isSalesViewEnabled(accountNumber);
    localStorage.setItem(storageKey, JSON.stringify(!currentValue));
    window.dispatchEvent(new Event('storage_ab_salesView'));
};
