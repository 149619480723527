import { isSalesViewEnabled } from '@ab-core-functions/sales-view';
import { userHasOnlySalesView } from '@ab-core-functions/user-permission';
import type { Query, QueryPositionPriceObjectArgs } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { POSITION_PRICE_OBJECT } from './gql/positionPriceObject';

type QueryDataType = Pick<Query, 'positionPriceObject'>;
type OutgoingDataType = QueryDataType['positionPriceObject'];
type IncomingDataType = QueryPositionPriceObjectArgs & { skip?: boolean };

const onError = getErrorLoggerFunction('positionPriceObject');

export const usePositionPriceObject = (props: IncomingDataType) => {
    const { productId, chargeNr, hidePrice, projectId, quantity, skip = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { user } = useAuth();
    const accountNumber = user?.activeAccount?.accountNumber || '';
    const onlySalesView = userHasOnlySalesView();
    const isSalesView = onlySalesView || isSalesViewEnabled(accountNumber);
    const isSkip = skip || !quantity || !productId;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryPositionPriceObjectArgs>(
        POSITION_PRICE_OBJECT,
        {
            variables: {
                hidePrice: hidePrice || isSalesView,
                productId,
                chargeNr,
                projectId,
                quantity
            },
            skip: isSkip,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.positionPriceObject);
        }
    }, [data]);

    return {
        positionPriceObject: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
