import { gql } from '@apollo/client';

export const SEARCH_BY_CATEGORY = gql`
    query SearchByCategory($category: String!, $page: Float) {
        searchByCategory(category: $category, page: $page) {
            results {
                document {
                    id
                    sku
                    productName
                    ean
                    packagingSize
                    supplierId
                    supplierName
                    productCocontractorValue
                    image
                    procuredProduct
                    productCocontractor
                    salesUnit
                    quantityUnit
                    category
                    hasAccessories
                    hasAlternatives
                    bonus
                    availability
                    customerArticleNumber
                    scoreCardIndex
                }
            }
            pageCount
            currentPage
            count
            total
        }
    }
`;
