import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_SKU_V2 = gql`
    query GetProductBySkuV2($sku: String!) {
        getProductBySkuV2(sku: $sku) {
            bonus {
                isTecselect
                isAbakus
                isAbakusPlus
                isPromotion
                isTopProduct
                isVipProduct
                isPremiumProduct
            }
            categories {
                id
                externalId
                slug
                name
                description
                stagedProductCount
                orderHint
                parent {
                    id
                }
                custom {
                    customFieldsRaw {
                        name
                        value
                    }
                }
                metaTitle
                metaDescription
            }
            customerArticleNumber
            deeplinks {
                name
                url
            }
            documents {
                name
                url
            }
            ean
            hasAccessories
            hasAlternatives
            id
            thumbnail
            packagingSize
            procuredProduct
            productCocontractor
            productName
            quantityUnit
            salesUnit
            sku
            supplierId
            supplierProductNumber
            isOnlineAvailable
            reachInfo
            relationType
            weightReference
            scoreCardIndex
            isUnqualifiedContractProduct
            isLimitedQuantity
            isRewardProduct
            images {
                url
                label
            }
            customTariffNumber
            description
            relatedProducts {
                alternativesEnventa
                accessoriesProducts
                similarProducts
                baseProducts
                necessaryAccessories
                necessarySelections
                parts
                spareParts
                replacements
            }
            productDocumentsDeha {
                name
                link
            }
            productFeatures {
                featureName
                featureValueScoped {
                    minValue
                    maxValue
                }
                featureValues
                unit
                version
            }
            slug
            supplierProductLink
            weight
            fiveYearGuarantee
            isRewardProduct
            purchase
        }
    }
`;
