import { gql } from '@apollo/client';

const AVAILABILITY_FRAGMENT = gql`
    fragment availabilityFields on ProductVariantAvailabilityWithChannel {
        availability {
            availableQuantity
        }
        channel {
            name
            key
        }
    }
`;

export const PRODUCTS_AVAILABILITIES_WITH_CHANNEL = gql`
    query ProductsAvailabilitiesWithChannel($skus: [String!]!, $cartId: String, $externalId: String) {
        productsAvailabilitiesWithChannel(skus: $skus, cartId: $cartId, externalId: $externalId) {
            sku
            aggregatedMainChannelQuantity
            procuredProduct
            deliveryFromFactory
            deliveryTimeInfoIdentifier
            mainChannelAvailability {
                ...availabilityFields
            }
            cartChannelAvailability {
                ...availabilityFields
            }
            pickupChannelsAvailability {
                ...availabilityFields
            }
            zajadaczChannelAvailability {
                ...availabilityFields
            }
            phillipsburgChannelAvailability {
                ...availabilityFields
            }
        }
    }
    ${AVAILABILITY_FRAGMENT}
`;
