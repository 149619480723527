import { gql } from '@apollo/client';

export const SEARCH_V2 = gql`
    query SearchV2($query: String!, $sid: String) {
        searchV2(query: $query, sid: $sid) {
            results {
                bonus
                ean
                customerArticleNumber
                hasAccessories
                hasAlternatives
                id
                image
                isPromotion
                packagingSize
                position
                productCocontractor
                productCocontractorValue
                productName
                quantityUnit
                salesUnit
                scoreCardIndex
                sku
                supplierId
                supplierName
                fiveYearGuarantee
                purchase
            }
            pageCount
            currentPage
            count
            total
            facets {
                associatedFieldName
                detailedLinks
                elements {
                    text
                    totalHits
                    clusterLevel
                    selected
                    absoluteMaxValue
                    absoluteMinValue
                    selectedMaxValue
                    selectedMinValue
                    searchParams {
                        filters {
                            name
                            values {
                                value
                            }
                        }
                    }
                }
                selectedElements {
                    text
                    totalHits
                    clusterLevel
                    selected
                    absoluteMaxValue
                    absoluteMinValue
                    selectedMaxValue
                    selectedMinValue
                    searchParams {
                        filters {
                            name
                            values {
                                value
                            }
                        }
                    }
                }
                filterStyle
                name
                selectionType
                showPreviewImages
                type
                unit
            }
        }
    }
`;
