import type { Query, QuerySearchByCategoryArgs } from '@ab-core/graphql/dist';
import { useFullUser } from '@ab-core/hooks/user/useFullUser';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_BY_CATEGORY } from './gql/searchByCategory';
import type { SearchDataMapperType } from './productSearchMapper';
import { searchDataMapper } from './productSearchMapper';

type QueryDataType = Pick<Query, 'searchByCategory'>;
type OutgoingDataType = SearchDataMapperType;

const onError = getErrorLoggerFunction('searchByCategory');

export const useSearchByCategory = (category: string, page = 1) => {
    const { user } = useFullUser();
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QuerySearchByCategoryArgs>(SEARCH_BY_CATEGORY, {
        variables: {
            category,
            page
        },

        onError
    });

    useEffect(() => {
        if (data?.searchByCategory) {
            const mappedData = searchDataMapper(data.searchByCategory, user);
            setOutgoingData(mappedData);
        }
    }, [data, user]);

    return {
        products: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
