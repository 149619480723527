import { getSalesViewStorageKey } from './getSalesViewStorageKey';

export const isSalesViewEnabled = (accountNumber?: string): boolean => {
    if (typeof window === 'undefined' || !accountNumber) {
        return false;
    }

    const storageKey = getSalesViewStorageKey(accountNumber);
    const storedValue = localStorage.getItem(storageKey);

    return JSON.parse(storedValue || 'false');
};
