import type { Query, QueryProductsAvailabilitiesWithChannelArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PRODUCTS_AVAILABILITIES_WITH_CHANNEL } from './gql/productsAvailabilitiesWithChannel';

type QueryDataType = Pick<Query, 'productsAvailabilitiesWithChannel'>;
type QueryReturnDataType = QueryDataType['productsAvailabilitiesWithChannel'];

export const useProductsAvailabilitiesWithChannel = (
    variables: QueryProductsAvailabilitiesWithChannelArgs,
    skip = false
) => {
    const [productsAvailabilitiesWithChannel, setProductsAvailabilitiesWithChannel] = useState<QueryReturnDataType>([]);

    const { data, loading, error, refetch } = useQuery<QueryDataType, QueryProductsAvailabilitiesWithChannelArgs>(
        PRODUCTS_AVAILABILITIES_WITH_CHANNEL,
        {
            variables,
            fetchPolicy: 'network-only',
            skip: variables.skus.length === 0 || skip
        }
    );

    useEffect(() => {
        if (data?.productsAvailabilitiesWithChannel && !loading) {
            setProductsAvailabilitiesWithChannel(data.productsAvailabilitiesWithChannel);
        }
    }, [data, loading]);

    return {
        error: !!error,
        success: !!data,
        productsAvailabilitiesWithChannel,
        loading,
        refetch
    };
};
