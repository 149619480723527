import { useAuth } from '@ab-core/hooks';
import { useCallback, useEffect, useState } from 'react';
import { isSalesViewEnabled } from './isSalesViewEnabled';

export const useSalesViewState = (): boolean => {
    const { user } = useAuth();
    const accountNumber = user?.activeAccount?.accountNumber || '';

    const getCurrentState = useCallback(() => {
        return isSalesViewEnabled(accountNumber);
    }, [accountNumber]);

    const [isEnabled, setIsEnabled] = useState<boolean>(getCurrentState);

    useEffect(() => {
        const handleStorageChange = () => {
            setIsEnabled(getCurrentState());
        };

        handleStorageChange();

        window.addEventListener('storage_ab_salesView', handleStorageChange);

        return () => {
            window.removeEventListener('storage_ab_salesView', handleStorageChange);
        };
    }, [accountNumber, getCurrentState]);

    return isEnabled;
};
